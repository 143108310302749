import{getLocale,getSettings}from'soapbox/actions/settings';import{importEntities}from'soapbox/entity-store/actions';import{Entities}from'soapbox/entity-store/entities';import{selectEntity}from'soapbox/entity-store/selectors';import messages from'soapbox/locales/messages';import{ChatKeys,isLastMessage}from'soapbox/queries/chats';import{queryClient}from'soapbox/queries/client';import{getUnreadChatsCount,updateChatListItem,updateChatMessage}from'soapbox/utils/chats';import{removePageItem}from'soapbox/utils/queries';import{play,soundCache}from'soapbox/utils/sounds';import{connectStream}from'../stream';import{deleteAnnouncement,updateAnnouncements,updateReaction as updateAnnouncementsReaction}from'./announcements';import{updateConversations}from'./conversations';import{fetchFilters}from'./filters';import{MARKER_FETCH_SUCCESS}from'./markers';import{updateNotificationsQueue}from'./notifications';import{updateStatus}from'./statuses';import{// deleteFromTimelines,
connectTimeline,disconnectTimeline,processTimelineUpdate}from'./timelines';const STREAMING_CHAT_UPDATE='STREAMING_CHAT_UPDATE';const removeChatMessage=payload=>{const data=JSON.parse(payload);const chatId=data.chat_id;const chatMessageId=data.deleted_message_id;// If the user just deleted the "last_message", then let's invalidate
// the Chat Search query so the Chat List will show the new "last_message".
if(isLastMessage(chatMessageId)){queryClient.invalidateQueries(ChatKeys.chatSearch());}removePageItem(ChatKeys.chatMessages(chatId),chatMessageId,(o,n)=>String(o.id)===String(n));};// Update the specific Chat query data.
const updateChatQuery=chat=>{const cachedChat=queryClient.getQueryData(ChatKeys.chat(chat.id));if(!cachedChat){return;}const newChat={...cachedChat,latest_read_message_by_account:chat.latest_read_message_by_account,latest_read_message_created_at:chat.latest_read_message_created_at};queryClient.setQueryData(ChatKeys.chat(chat.id),newChat);};const connectTimelineStream=function(timelineId,path){let pollingRefresh=arguments.length>2&&arguments[2]!==undefined?arguments[2]:null;let accept=arguments.length>3&&arguments[3]!==undefined?arguments[3]:null;let opts=arguments.length>4?arguments[4]:undefined;return connectStream(path,pollingRefresh,(dispatch,getState)=>{const locale=getLocale(getState());return{onConnect(){dispatch(connectTimeline(timelineId));},onDisconnect(){dispatch(disconnectTimeline(timelineId));},onReceive(websocket,data){switch(data.event){case'update':dispatch(processTimelineUpdate(timelineId,JSON.parse(data.payload),accept));break;case'status.update':dispatch(updateStatus(JSON.parse(data.payload)));break;// FIXME: We think delete & redraft is causing jumpy timelines.
// Fix that in ScrollableList then re-enable this!
//
// case 'delete':
//   dispatch(deleteFromTimelines(data.payload));
//   break;
case'notification':messages[locale]().then(messages=>{dispatch(updateNotificationsQueue(JSON.parse(data.payload),messages,locale,window.location.pathname));}).catch(error=>{console.error(error);});break;case'conversation':dispatch(updateConversations(JSON.parse(data.payload)));break;case'filters_changed':dispatch(fetchFilters());break;case'pleroma:chat_update':case'chat_message.created':// TruthSocial
dispatch((_dispatch,getState)=>{var _chat$last_message;const chat=JSON.parse(data.payload);const me=getState().me;const messageOwned=((_chat$last_message=chat.last_message)===null||_chat$last_message===void 0?void 0:_chat$last_message.account_id)===me;const settings=getSettings(getState());// Don't update own messages from streaming
if(!messageOwned){var _opts$statContext;updateChatListItem(chat);if(settings.getIn(['chats','sound'])){play(soundCache.chat);}// Increment unread counter
opts===null||opts===void 0?void 0:(_opts$statContext=opts.statContext)===null||_opts$statContext===void 0?void 0:_opts$statContext.setUnreadChatsCount(getUnreadChatsCount());}});break;case'chat_message.deleted':// TruthSocial
removeChatMessage(data.payload);break;case'chat_message.read':// TruthSocial
dispatch((_dispatch,getState)=>{const chat=JSON.parse(data.payload);const me=getState().me;const isFromOtherUser=chat.account.id!==me;if(isFromOtherUser){updateChatQuery(JSON.parse(data.payload));}});break;case'chat_message.reaction':// TruthSocial
updateChatMessage(JSON.parse(data.payload));break;case'pleroma:follow_relationships_update':dispatch(updateFollowRelationships(JSON.parse(data.payload)));break;case'announcement':dispatch(updateAnnouncements(JSON.parse(data.payload)));break;case'announcement.reaction':dispatch(updateAnnouncementsReaction(JSON.parse(data.payload)));break;case'announcement.delete':dispatch(deleteAnnouncement(data.payload));break;case'marker':dispatch({type:MARKER_FETCH_SUCCESS,marker:JSON.parse(data.payload)});break;}}};});};function followStateToRelationship(followState){switch(followState){case'follow_pending':return{following:false,requested:true};case'follow_accept':return{following:true,requested:false};case'follow_reject':return{following:false,requested:false};default:return{};}}function updateFollowRelationships(update){return(dispatch,getState)=>{const me=getState().me;const relationship=selectEntity(getState(),Entities.RELATIONSHIPS,update.following.id);if(update.follower.id===me&&relationship){const updated={...relationship,...followStateToRelationship(update.state)};// Add a small delay to deal with API race conditions.
setTimeout(()=>dispatch(importEntities([updated],Entities.RELATIONSHIPS)),300);}};}export{STREAMING_CHAT_UPDATE,connectTimelineStream};